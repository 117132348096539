import Vue from 'vue'
import VueRouter from 'vue-router'
import ReferralStatistics from '@/components/ReferralStatistics.vue'
import HighscoreReferrals from '@/components/HighscoreReferrals.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: "/referralStatistics/:encryptedId",
    name: "referral-statistics",
    component: ReferralStatistics,
    props: true
  },
  {
    path: "/highscore/:topReferralNumber",
    name: "highscore-referrals",
    component: HighscoreReferrals,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
