<template>
  <div>
    <div class="px-4 py-5 my-5 text-center">
      <img alt="BSI Logo" src="../assets/bsi-logo.png" />

      <!--<img class="d-block mx-auto mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57">-->
      <!--<h1 class="display-5 fw-bold">Referral statistics page</h1>-->
      <div class="col-lg-12 mx-auto">
        <!---->

        <vue-cookie-accept-decline
          :debug="false"
          :disableDecline="true"
          :showPostponeButton="false"
          elementId="myPanel1"
          position="bottom"
          ref="myPanel1"
          transitionName="slideFromBottom"
          type="bar"
        >
          <!-- Optional -->
          <template #postponeContent>&times;</template>

          <!-- Optional -->
          <template #message>
            <span
              >By clicking 'Got It!', you accept that this website stores
              personal information for the duration of the contest to track fair
              participation. To make an inquiry about your stored data write to
            </span>
            <a
              style="
                margin-left: 10px;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                border-radius: 8px;
                padding: 5px;
                background: #389ce9;
                text-decoration: none;
                color: #fff;
              "
              :href="'https://t.me/' + telegramContactName"
              target="_blank"
              ><svg
                style="
                  width: 30px;
                  height: 20px;
                  vertical-align: middle;
                  margin: 0px 5px;
                "
                viewBox="0 0 21 18"
              >
                <g fill="none">
                  <path
                    fill="#ffffff"
                    d="M0.554,7.092 L19.117,0.078 C19.737,-0.156 20.429,0.156 20.663,0.776 C20.745,0.994 20.763,1.23 20.713,1.457 L17.513,16.059 C17.351,16.799 16.62,17.268 15.88,17.105 C15.696,17.065 15.523,16.987 15.37,16.877 L8.997,12.271 C8.614,11.994 8.527,11.458 8.805,11.074 C8.835,11.033 8.869,10.994 8.905,10.958 L15.458,4.661 C15.594,4.53 15.598,4.313 15.467,4.176 C15.354,4.059 15.174,4.037 15.036,4.125 L6.104,9.795 C5.575,10.131 4.922,10.207 4.329,10.002 L0.577,8.704 C0.13,8.55 -0.107,8.061 0.047,7.614 C0.131,7.374 0.316,7.182 0.554,7.092 Z"
                  ></path>
                </g></svg
              >Telegram</a
            >
          </template>

          <!-- Optional -->
          <template #declineContent>Opt Out</template>

          <!-- Optional -->
          <template #acceptContent>Got It!</template>
        </vue-cookie-accept-decline>

        <div v-if="errorMessage">
          {{ errorMessage }}
        </div>
        <div v-else>
          <div v-if="isLoading" class="loader"></div>
          <div v-else>
            <div class="lead mb-4">
              <h1 class="display-5 fw-bold">
                Hello referral {{ referralName }}
              </h1>
            </div>

            <div class="lead mb-4" v-if="referralData">
              Total new users referred: {{ referralData.length }}
            </div>
            <div class="lead mb-4" v-else>
              No userdata found for this referral
            </div>

            <div class="lead mb-4" v-if="newUserSessionReferralData">
              Total incomplete referrals (clicked link but did not finish registration):
              {{ newUserSessionReferralData.length }}
            </div>
            <div class="lead mb-4" v-else>
              No new user session data found for this referral
            </div>

            <div v-if="referralData && referralData.length">
              <h2 class="lead mb-12"><b>All referred users table</b></h2>
              <v-data-table
                :headers="referredUsersHeaders"
                :items="referralData"
                :items-per-page="30"
                class="elevation-1"
              >
                <template #[`item.username`]="{ item }">
                  <a target="_blank" :href="`https://t.me/${item.username}`">
                    {{ item.username }}
                  </a>
                </template>
              </v-data-table>
            </div>
            <br />
            <div
              v-if="
                newUserSessionReferralData && newUserSessionReferralData.length
              "
            >
              <h2 class="lead mb-12">
                <b>All users who did not join (but clicked link)</b>
              </h2>
              <v-data-table
                :headers="referredNewUsersHeaders"
                :items="newUserSessionReferralData"
                :items-per-page="30"
                class="elevation-1"
              >
                <template #[`item.username`]="{ item }">
                  <a target="_blank" :href="`https://t.me/${item.username}`">
                    {{ item.username }}
                  </a>
                </template>
              </v-data-table>
              <!--</div>-->
            </div>
          </div>
        </div>

        <!---->
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";

export default {
  name: "ReferralStatistics",
  data: function () {
    return {
      errorMessage: null,
      isLoading: true,
      referralName: null,
      encryptedId: null,
      baseUrl: process.env.VUE_APP_ROOT_API_URL,
      telegramContactName: process.env.VUE_APP_TELEGRAM_CONTACT_NAME,
      referralData: null,
      newUserSessionReferralData: null,
      referredUsersHeaders: [
        { text: "Date", value: "timestamp" },
        { text: "Username", value: "username" },
        { text: "UserId", value: "userid" },
      ],
      referredNewUsersHeaders: [
        { text: "Date", value: "timestamp" },
        { text: "Username", value: "username" },
        { text: "UserId", value: "userid" },
        { text: "Already was Member", value: "initialischatmember" },
        { text: "Last Step", value: "latestStep" },
      ],
    };
  },
  components: { VueCookieAcceptDecline },

  methods: {
    async getReferralInformation() {
      // get all new user referral information to further process
      let mydata = (
        await axios.get(
          this.baseUrl +
            "/api/v1/referral/referralStatistics/" +
            this.encryptedId
        )
      ).data;
      if (mydata.error) {
        this.errorMessage = mydata.message;
      } else {
        this.referralData = mydata.data.data;
      }

      // get all new user session information for that referral for further process
    },
    async getNewUserSessionForReferralInformation() {
      // get all new user referral information to further process
      let mydata = (
        await axios.get(
          this.baseUrl +
            "/api/v1/referral/userSessionNotReferredEnrichedForReferralInformation/" +
            this.encryptedId
        )
      ).data;
      if (mydata.error) {
        this.errorMessage = mydata.message;
      } else {
        this.newUserSessionReferralData = mydata.data.data;
      }

      // get all new user session information for that referral for further process
    },
    async calculateStatistics() {
      // first get referral information
      await this.getReferralInformation();
      // second get all new user session information for that referral for further process
      await this.getNewUserSessionForReferralInformation();
    },
    async checkEncryptedUserId() {
      // check if id is correctly encrypted
      let mydata = (
        await axios.get(
          this.baseUrl +
            "/api/v1/referral/validateNewUserEncryptedId/" +
            this.encryptedId
        )
      ).data;

      if (mydata.error) {
        this.errorMessage = mydata.message;
      }
      // if data could be decrypted
      else {
        this.errorMessage = null;
        this.referralName = mydata.data.referralName;
        this.isLoading = false;
      }
    },
  },
  // http://localhost:8084/inspectUser/1977979186
  mounted: async function () {
    // this must be the first expression ever...we need it in every method
    this.encryptedId = this.$route.params.encryptedId;

    if (!this.encryptedId) {
      return;
    }

    // check encrypted user id
    this.checkEncryptedUserId();

    // calculate statistics
    this.calculateStatistics();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table-responsive-stack tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.table-responsive-stack td,
.table-responsive-stack th {
  display: block;
  /*      
   flex-grow | flex-shrink | flex-basis   */
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.table-responsive-stack .table-responsive-stack-thead {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .table-responsive-stack tr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-bottom: 3px solid #ccc;
    display: block;
  }
  /*  IE9 FIX   */
  .table-responsive-stack td {
    float: left\9;
    width: 100%;
  }
}
</style>
