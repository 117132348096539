<template>
  <div class="px-4 py-5 my-5 text-center">
    <img alt="BSI Logo" src="../assets/bsi-logo.png" />

    <div v-if="isLoading" class="loader"></div>

    <div v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-else>
      <!--<img class="d-block mx-auto mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57">-->
      <!--<h1 class="display-5 fw-bold">Referral statistics page</h1>-->

      <div v-if="topReferrals" class="col-lg-12 mx-auto">
        <v-data-table
          :headers="referredUsersHeaders"
          :items="topReferrals"
          :items-per-page="50"
          class="elevation-1"
        >
          <template #[`item.username`]="{ item }">
            <a target="_blank" :href="`https://t.me/${item.username}`">
              {{ item.username }}
            </a>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HighscoreReferrals",
  data: function () {
    return {
      errorMessage: null,
      topReferralNumber: null,
      topReferrals: null,
      isLoading: true,
      baseUrl: process.env.VUE_APP_ROOT_API_URL,
      referredUsersHeaders: [
        { text: "Username", value: "referredbyusername" },
        { text: "UserId", value: "referredbyid" },
        { text: "Score", value: "mycount" },
      ],
    };
  },
  methods: {
    async getTopHighscore() {
      // check if id is correctly encrypted
      let myReturnObj = (
        await axios.get(
          this.baseUrl + "/api/v1/referral/highscore/" + this.topReferralNumber
        )
      ).data;

      if (myReturnObj.error) {
        this.errorMessage = myReturnObj.message;
      }
      // if data could be decrypted
      else {
        this.errorMessage = null;
        this.isLoading = false;
        this.topReferrals = myReturnObj.data.topReferrals;
      }
    },
  },
  mounted: async function () {
    this.topReferralNumber = this.$route.params.topReferralNumber;

    if (!this.topReferralNumber) {
      return;
    }
    this.getTopHighscore();
  },
};
</script>
<style>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>